import React from "react";
import { graphql } from "gatsby";

import MusicPage from "@components/MusicPage";

const Music = ({ data }) => {
  const { pageData } = data;
  return <MusicPage {...pageData} />;
};

export const query = graphql`
  query MusicPageQuery {
    pageData: sanityMusicPage {
      title
      intro {
        ...SanityTextBoxFragment
      }
      backgroundImage {
        ...SanityBackgroundImageFragment
      }
      playlistBlock {
        songs {
          title
          asset {
            asset {
              url
            }
          }
          artist
          songId
        }
        backgroundImage {
          ...SanityBackgroundImageFragment
        }
      }
      musicBlock {
        content {
          ...SanityTextBoxFragment
        }
        backgroundColor {
          value
          title
        }
      }
    }
  }
`;

export default Music;
