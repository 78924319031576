import React from "react";

import { convertBgImageToSources } from "@utils/templateHelpers";

import Section from "@components/Section";

import Playlist from "@components/Playlist";
import AppStoreButtons from "@components/AppStoreButtons";

import * as styles from "./styles.module.css";

const PlaylistBlock = ({ backgroundImage, songs }) => {
  const sources = convertBgImageToSources(
    backgroundImage,
    "(min-width: 768px)",
  );

  return (
    <Section className={styles.container} backgroundImageSources={sources}>
      <Playlist {...{ songs }} />
      <AppStoreButtons className={styles.buttonsWrapper} />
    </Section>
  );
};

export default PlaylistBlock;
