import React from "react";

import TextBox from "@components/TextBox";
import MusicBlock from "@components/MusicBlock";
import PlaylistBlock from "@components/PlaylistBlock";
import Hero from "@components/Hero";

import * as styles from "./styles.module.css";

const MusicPage = ({
  title,
  backgroundImage,
  intro,
  musicBlock,
  playlistBlock,
}) => {
  return (
    <>
      <Hero
        {...{
          title,
          backgroundImage,
          className: styles.hero,
        }}
      >
        <TextBox {...intro} className={styles.box} />
      </Hero>

      <MusicBlock {...musicBlock} />
      <PlaylistBlock {...playlistBlock} />
    </>
  );
};

export default MusicPage;
