import React from "react";

import TextBox from "@components/TextBox";
import Section from "@components/Section";

import * as styles from "./styles.module.css";

const MusicBlock = ({ content, backgroundColor }) => {
  return (
    <Section
      as="section"
      className={styles.container}
      backgroundColor={backgroundColor?.value}
    >
      <TextBox {...content} whiteBox={false} className={styles.box} />
    </Section>
  );
};

export default MusicBlock;
