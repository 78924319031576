import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.css";

const Playlist = ({ songs }) => {
  const query = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const { siteUrl } = query.site.siteMetadata;

  const [currentTrack, setCurrentTrack] = useState();
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef();

  const handlePlayState = async () => {
    setIsPlaying(!isPlaying);

    if (isPlaying) {
      audioRef.current.pause();
    } else if (audioRef.current.readyState > 0) {
      audioRef.current.play();
    }
  };

  const handleTrackClick = async track => {
    setCurrentTrack(track);
    handlePlayState();
  };

  useEffect(() => {
    const audioEl = audioRef.current;

    if (currentTrack) {
      const onPlay = () => {
        setIsPlaying(true);
      };
      const onPause = () => {
        setIsPlaying(false);
      };
      const onEnd = () => {
        setIsPlaying(false);
      };

      const onCanPlay = () => {
        const { songId } = currentTrack;

        if (window.location.origin === siteUrl) {
          fetch("https://trallapp.no/tono_logging", {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify({ songId }),
          });
        }

        audioEl.play();
        audioEl.removeEventListener("canplay", onCanPlay);
      };

      audioEl.addEventListener("canplay", onCanPlay);
      audioEl.addEventListener("play", onPlay);
      audioEl.addEventListener("pause", onPause);
      audioEl.addEventListener("onended", onEnd);

      return () => {
        audioEl.removeEventListener("play", onPlay);
        audioEl.removeEventListener("pause", onPause);
        audioEl.removeEventListener("onended", onEnd);
      };
    }
  }, [currentTrack, siteUrl]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.audioWrap}>
        <header>
          <div>
            <h3>
              {!currentTrack ? "Hør noen smakebiter" : currentTrack.title}
            </h3>
            {currentTrack ? <p>{currentTrack.artist}</p> : <>&nbsp;</>}
          </div>
        </header>

        <div className={styles.playerWrap}>
          <audio
            className={styles.audio}
            key={currentTrack?.title}
            ref={audioRef}
            preload="true"
            controlsList="nodownload noplaybackrate"
            controls
          >
            <source src={currentTrack?.asset?.asset?.url} type="audio/mp4" />
          </audio>

          {!currentTrack && (
            <button
              aria-label="Play"
              onClick={() => {
                if (!currentTrack) {
                  handleTrackClick(songs[0]);
                }
              }}
            ></button>
          )}
        </div>
      </div>

      <ul className={styles.list}>
        {songs.map(song => {
          const { title, artist } = song;
          const isCurrentTrack = currentTrack?.title === title;
          const isCurrentPlaying = isCurrentTrack && isPlaying;

          return (
            <li
              key={title}
              className={cx(styles.track, isCurrentTrack && styles.isCurrent)}
            >
              <span className={styles.buttonPlay}>
                {isCurrentPlaying && isPlaying ? (
                  <svg
                    width="14"
                    height="19"
                    viewBox="0 0 14 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 1.89526C10 1.34298 10.4477 0.895264 11 0.895264H13C13.5523 0.895264 14 1.34298 14 1.89526V17.8953C14 18.4475 13.5523 18.8953 13 18.8953H11C10.4477 18.8953 10 18.4475 10 17.8953V1.89526Z"
                      fill="var(--darkPurple)"
                    />
                    <path
                      d="M0 1.89526C0 1.34298 0.447715 0.895264 1 0.895264H3C3.55228 0.895264 4 1.34298 4 1.89526V17.8953C4 18.4475 3.55228 18.8953 3 18.8953H1C0.447715 18.8953 0 18.4475 0 17.8953V1.89526Z"
                      fill="var(--darkPurple)"
                    />
                  </svg>
                ) : (
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 5.82832V19.9622C4 21.4916 5.94851 22.4134 7.42531 21.5825L19.9862 14.5155C21.3379 13.755 21.3379 12.0355 19.9862 11.275L7.42531 4.20805C5.94851 3.37718 4 4.29888 4 5.82832Z"
                      fill="var(--darkPurple)"
                    />
                  </svg>
                )}
              </span>

              <div>
                <h4>{title}</h4>
                <p>{artist}</p>
              </div>

              <button
                className={styles.button}
                onClick={() => handleTrackClick(song)}
                aria-label={`${
                  isCurrentPlaying && isPlaying ? "Pause" : "Play"
                } ${title} by ${artist}`}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Playlist;
