// extracted by mini-css-extract-plugin
export var sizes = "\"@styles/variables.css\"";
export var mediumScreen = "48rem";
export var xLargeScreen = "75rem";
export var wrapper = "styles-module--wrapper--yTUpb";
export var list = "styles-module--list--jy2ui";
export var track = "styles-module--track--1sZuu";
export var button = "styles-module--button--LE4l5";
export var isCurrent = "styles-module--isCurrent--3eAXf";
export var audioWrap = "styles-module--audioWrap--3s_Fh";
export var playerWrap = "styles-module--playerWrap--1R99_";
export var audio = "styles-module--audio--2Qow_";
export var buttonPlay = "styles-module--buttonPlay--N3O9Y";